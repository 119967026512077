import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Banner.css';
import companyLogo from '../../assets/sarg-logo.svg';

const bannerImages = [
  {
    url: "/banner-1.png",
    caption: 'First Banner Image'
  },
  {
    url: "/banner-2.png",
    caption: 'Second Slide'
  },
  {
    url: "/banner-3.png",
    caption: 'Third Slide'
  },
  {
    url: "/banner-5.png",
    caption: 'Third Slide'
  },
  {
    url: "/banner-6.png",
    caption: 'Third Slide'
  },
  {
    url: "/banner-7.png",
    caption: 'Third Slide'
  }
];

function Banner() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500
  };
  return (
    <section className="banner-main-wrapper component-section pt-0">
      <Slider {...settings}>
        {bannerImages.map((bImage, index) => (
          <div className="each-fade" key={index}>
            <div className="image-container" style={{backgroundImage:`url(${require('../../assets/banner' +bImage.url)})`}}></div>
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default Banner;