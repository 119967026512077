import React from 'react';
import Navbar  from '../components/Navbar/Navbar';

function Header() {
  return (
    <header>
        <Navbar />
    </header>
  )
}

export default Header;