import React, { usestate } from 'react';
import Iframe from 'react-iframe';
import './Location.css';

function Location() {
  return (
    <section className="location-wrapper component-section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="location-embaded">
                    <Iframe url="https://maps.google.com/maps?q=titanum%20heights,%20nr.%20vodaphone&t=&z=13&ie=UTF8&iwloc=&output=embed"
        id="locationMap"
        className="locationMap"
        display="initial"
        position="relative"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Location;