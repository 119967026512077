import React from 'react';
import Header  from './container/Header';
import Wrapper  from './container/Wrapper';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

function App(){
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact/>
        </Routes>
        <Wrapper />
      </Router>
    </>
  );
}


export default App;