import React, { usestate } from 'react';
import './Navbar.css';
import { Link }  from 'react-router-dom';
import Logo from '../../assets/sarg-logo.svg';

function Navbar() {
  return (
    <nav className="nav">
        <div className="container">
            <div className="row">
                <div className="col d-flex align-items-center justify-content-center">
                    <div className="navbar-logo-wrapper">
                        <Link to="/" className="navbar-logo">
                            <img src={Logo} alt="Sarg Articture"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
  )
}

export default Navbar;