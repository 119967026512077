import React from 'react';
import Banner from '../components/Banner/Banner';
import Location from '../components/Location/Location';
import ContactForm from '../components/Contact/ContactForm';

function Header() {
  return (
    <article className="main-content-wrapper">
        <Banner />
        <Location />
        <ContactForm />
    </article>
  )
}

export default Header;