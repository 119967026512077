
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import './ContactForm.css';

function Location() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_a1oibdo', 'template_f5rqk6i', form.current, 'wDbyIrlE6FtcuQAVU')
      .then((result) => {
          if(result.text == "OK"){
            alert('Thank you for sending this message we will contact you soon !');
          }
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };
  return (
    <section className="contact-wrapper component-section pb-100">
        <div className="container">
          <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                  <div className="col-12 offset-md-2 col-md-8">
                    <div className="row pt-5 mx-auto">
                      <div className="col-6 form-group mx-auto">
                        <input type="text" className="form-control" placeholder="First Name" name="first_name" />
                      </div>
                      <div className="col-6 form-group mx-auto">
                        <input type="text" className="form-control" placeholder="Last Name" name="last_name" />
                      </div>
                    </div>
                    <div className="row pt-5 mx-auto">
                      <div className="col-12 form-group mx-auto">
                        <input type="email" className="form-control" placeholder="Email Address" name="user_email" />
                      </div>
                    </div>
                    <div className="row pt-5 mx-auto">
                      <div className="col-12 form-group mx-auto">
                        <textarea className="form-control" cols="30" rows="10" placeholder="Your Message" name="message" />
                        <input type="submit" className="btn btn-info" value="Send Message" />
                      </div>
                    </div>
                  </div>
              </div>
          </form>
        </div>
    </section>
  )
}

export default Location;